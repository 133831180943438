import React, { useEffect } from 'react';

const HubspotForm = ({ region, portalId, formId, setFormSubmitted, displayID }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = '//js.hsforms.net/forms/v2.js';
    document.body.appendChild(script);

    script.addEventListener('load', () => {
      // @TS-ignore
      if (window.hbspt) {
        // @TS-ignore
        window.hbspt.forms.create({
          region: `${region}`,
          portalId: `${portalId}`,
          formId: `${formId}`,
          target: displayID ? `#${displayID}` : '#hubspotForm',
          onFormSubmitted($form) {
            if (setFormSubmitted) setFormSubmitted(true);
          },
        });
      }
    });
  }, []);

  return (
    <div>
      <div id={displayID ? displayID : 'hubspotForm'}></div>
    </div>
  );
};

export default HubspotForm;
