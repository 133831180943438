import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CloseModalIcon } from '../Icon';

export default function DialogHandler({ isOpen, closeModal, webinarEmbed }) {
  // https://vital.wistia.com/embed/medias/dilovtax0t
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-[99999]" onClose={closeModal}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-[.75]" />
        </Transition.Child>

        <div className="hide-scrollbar fixed inset-0 overflow-y-auto">
          <div className={`flex min-h-full items-center justify-center p-4 text-center`}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0  scale-95"
            >
              <Dialog.Panel
                className={`relative w-full transform overflow-hidden align-middle font-heading transition-transform rounded-[.5rem] shadow-xl aspect-[16/9] max-w-[55.5rem] bg-navy`}
                style={{ fontFamily: '__PolySansFont_fcc87a' }}
              >
                <CloseDialog closeModal={closeModal} />
                {/* Dialog Content */}
                <div className="saf-of relative aspect-[16/9] w-full overflow-hidden rounded-[0.375rem] bg-magenta">
                  <iframe
                    title="Webinar Video"
                    src={webinarEmbed}
                    width="100%"
                    height="100%"
                    frameBorder="0"
                    allow="autoplay; fullscreen; picture-in-picture"
                    allowFullScreen
                  />
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

function CloseDialog({ closeModal }) {
  return (
    <button
      type="button"
      aria-label="Close Modal Button"
      className="transition-color absolute right-4 top-4 z-10 flex aspect-[1/1] h-6 w-6 shrink-0 items-center justify-center rounded-full duration-200 text-white hover:text-blue"
      onClick={closeModal}
    >
      <CloseModalIcon />
    </button>
  );
}
